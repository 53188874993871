import { createStore } from 'vuex'

export default createStore({
  state: {
    baseurl: 'https://api.lanzhoulan.cn/',
    project: 'shsx',
    version: 'V2.0.1',
    wx_barlist: [{
      name: '预约',
      url: '/yuyue',
      icon: 'completed-o',
    }, {
      name: '订单',
      url: '/myorder',
      icon: 'records-o',
    }, {
      name: '我',
      url: '/zhuce',
      icon: 'user-o',
    }],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})