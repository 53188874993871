import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes : Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/yuyue',
    name: 'yuyue',
    component: () => import(/* webpackChunkName: "about" */ '../views/yuyueIndex.vue')
  },
  {
    path: '/myorder',
    name: 'myorder',
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder.vue')
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    component: () => import(/* webpackChunkName: "about" */ '../views/zhuCe.vue')
  },
  {
    path: '/ditu',
    name: 'ditu',
    component: () => import(/* webpackChunkName: "about" */ '../views/diTu.vue')
  },
  {
    path: '/guanli',
    name: 'guanli',
    component: () => import(/* webpackChunkName: "about" */ '../views/adminIndex.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router