<template>
  <router-view />
</template>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: none !important;
  }

  html,
  body,
  #app {
    color: #606266;
    height: 100%;
    font-size: 0.2rem;
    font-family: Helvetica, "微软雅黑";
  }

  .foots {
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.2rem;
    text-align: center;
    color: #dddddd;
  }
</style>