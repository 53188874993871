import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入vant
import Vant from 'vant';
import 'vant/lib/index.css'

import '@/assets/styles/icon.css';

//字体rem
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 750) {
    htmlWidth = 750;
  }
  if (htmlWidth <= 220) {
    htmlWidth = 220;
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();


createApp(App).use(store).use(router).use(Vant).mount('#app')
